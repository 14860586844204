import buildQuery from "../../helpers/query/buildQuery"
import api from "../api"
import apiTC from "../apiTC"

export const getListInvoiceValidation = (params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `requests?${query}`,
        method: 'GET',
    })
}

export const getListInvoiceValidationTC = (params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `requests?${query}`,
        method: 'GET',
    })
}