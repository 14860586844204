import React, {Component} from 'react'
import PubSub from 'pubsub.js'
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'
import PropTypes from "prop-types"
import {createRelabel, createRelabelTC} from '../../../../services/api/RelabelServices'

class CreateRelabelModal extends Component {
    state = {
        file: '',
        error: '',
        validate: {
            file: false
        },
        loading: false
    }

    _onChangeFile = e => {
        if (e.target.files[0]) {
            const extension = e.target.files[0].name.replace(/.+\.(?=[^.]+$)/, '')

            if (extension !== 'csv' && e.target.files[0].name !== 'csv') {
                return this.setState({
                    validate: {
                        ...this.state.validate,
                        file: true,
                    }
                })
            }

            this.setState({
                file: e.target.files[0]
            }, () => {
                this._handleValidate()
            })
        }
    }

    _onSubmit = async () => {
        const {file} = this.state
        const {partner} = this.props
        const formData = new FormData()

        formData.append('change_label', file)

        if (!file) {
            this._handleValidate()
            return
        }

        this.setState({
            loading: true
        })

        const {success, message} = partner === "1C" ? await createRelabel(formData) : await createRelabelTC(formData)

        if (!success) {
            return this.setState({
                loading: false,
                error: message
            })
        }

        this._onToggle()

        PubSub.publish('LOAD_LIST_RELABELS')

        this.setState({
            loading: false
        })

    }

    _handleValidate = () => {
        const {file} = this.state

        this.setState({
            validate: {
                file: file === ''
            }
        })
    }

    _onToggle = () => {
        this.props.onToggleModal()
    }

    render() {
        const {loading, error, validate} = this.state
        const {isOpen, onToggleModal} = this.props

        return (
            <Modal isOpen={isOpen} className="CreateInvoiceModal">
                <ModalHeader toggle={onToggleModal}>Create Relabel</ModalHeader>

                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label>Upload file CSV</label>
                            <input type="file" accept=".csv" className="form-control-file"
                                   onChange={this._onChangeFile}/>
                            {validate.file && (
                                <div className="text-danger">Please choose file *.csv</div>
                            )}
                        </div>
                    </form>
                    {error && (
                        <div className="text-danger mt-3">{error}</div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="ModalButtons">
                        <button className="btn btn-light border mr-2" onClick={this._onToggle}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this._onSubmit} disabled={loading}>
                            {loading ? <span>Uploading...</span> : 'Upload'}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

CreateRelabelModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired,
}

export default CreateRelabelModal