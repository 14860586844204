import React, {Component} from 'react'
import { Link, Route } from "react-router-dom"

class NavbarLeft extends Component {
    _getNavbars = () => {
        return [
            {
                title: 'Labels',
                key: '1',
                icon: 'dropbox',
                href: '/',
                exact: true,
            },
            {
                title: 'Invoice validation',
                key: '2',
                icon: 'clone',
                href: '/a/invoice-validation',
            },
            {
                title: 'Export error shipping',
                key: '3',
                icon: 'download',
                href: '/a/export-errors-shipping',
            },
            {
                title: 'Relabels',
                key: '4',
                icon: 'retweet',
                href: '/a/relabels',
            },
            {
                title: 'Supplier validation',
                key: '5',
                icon: 'car',
                href: '/a/supplier-validation',
            },
        ]
    }

    render() {
        const navbar = this._getNavbars()

        return (
            <div className="bg-dark text-light navLeft">
                <ul>
                    {
                        navbar.map(nav => (
                            <Route
                                key={nav.key}
                                path={nav.href}
                                exact={nav.exact}
                                children={({match}) => {
                                    let active = match ? 'active' : ''
                                    return (
                                        <li className={active}>
                                            <i className={`fas fa-${nav.icon}`}/>
                                            <Link to={nav.href}>{nav.title}</Link>
                                        </li>
                                    )
                                }}
                            />
                        ))
                    }
                </ul>

            </div>
        )
    }
}

export default NavbarLeft