import React from 'react'
import PropTypes from 'prop-types'
import ExportRecords from "../components/ExportRecords"

SupplierValidationFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    idInvoice: PropTypes.string.isRequired,
    onHandleChangeStatus: PropTypes.func.isRequired,
    onHandleResetFilter: PropTypes.func.isRequired,
    onHandleChangePaymentStatus: PropTypes.func.isRequired
}

function SupplierValidationFilter(props) {
    const {
        filter,
        idInvoice,
        total,
        onHandleChangeStatus,
        filename,
        partner
    } = props

    return (
        <div className="FilterRecords mt-3 mb-3 d-lg-flex justify-content-end align-items-start">
            <div className="FilterStatus d-lg-flex align-items-center mr-3">
                <div className="SelectWrap position-relative mb-3 mb-lg-0 d-inline-block">
                    <select onChange={onHandleChangeStatus} className="form-control StatusFilter">
                        <option value="">All status</option>
                        <option value="done">Done</option>
                        <option value="failed">Failed</option>
                    </select>
                    <i className="fa fa-caret-down position-absolute"/>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <ExportRecords
                    request_id={idInvoice}
                    filename={filename}
                    filter={filter}
                    partner={partner}
                />
                <div className="total ml-3"><b>{total} {total === 1 ? 'item' : 'items'}</b></div>
            </div>
        </div>
    )
}

export default SupplierValidationFilter
