import React from 'react'
import LoginPage from './LoginPage'
import '../styles/index.scss'

function LoginContainer(props) {
    return (
        <div className="LoginContainer vh-100">
            <LoginPage {...props} />
        </div>
    )
}

export default LoginContainer
