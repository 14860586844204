import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import DownloadInvoice from "./DownloadInvoice"
import StatusFile from "./StatusFile"
import {INVOICE_CARRIERS} from "../../../../../helpers/consts"

const carrierBySlug = INVOICE_CARRIERS.reduce((mem, carrier) => {
    return {
        ...mem,
        [carrier.value]: carrier
    }
}, {})

class TableRow extends Component {
    render() {
        const {index, item, page, limit, partner} = this.props

        return (
            <tr className='TableRow'>
                <td>{limit * (page - 1) + index + 1}</td>
                <td>{item.filename}</td>
                <td>{humanizeTime(item.created)}</td>
                <td>{carrierBySlug[item.carrier] && carrierBySlug[item.carrier].label || '-'}</td>
                <StatusFile status={item.status}/>
                <DownloadInvoice id={item._id} status={item.status} partner={partner}/>
            </tr>
        )
    }
}

TableRow.propTypes = {
    limit: PropTypes.number,
    page: PropTypes.number,
    index: PropTypes.number,
    item: PropTypes.object
}

export default TableRow