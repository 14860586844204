import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'
import PropTypes from "prop-types"

class ConfirmForceApprove extends Component {

    _onSubmit = async () => {
        const {handleSubmit} = this.props
        handleSubmit()
    }

    _onToggle = () => {
        this.props.onToggleModal()
    }

    render() {
        const {loading, error, order_number, isOpen, onToggleModal} = this.props

        return (
            <Modal isOpen={isOpen} className="ActionModal">
                <ModalHeader toggle={onToggleModal}>Confirm Approve</ModalHeader>

                <ModalBody>
                    Are you sure to force approve request <b>{order_number}</b>?
                    {error && (
                        <div className="text-danger mt-3">{error}</div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="ModalButtons">
                        <button className="btn btn-light border mr-2" onClick={this._onToggle}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this._onSubmit} disabled={loading}>
                            {loading ? 'Approving...' : 'Approve'}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

ConfirmForceApprove.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default ConfirmForceApprove