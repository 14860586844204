import React from 'react'
import { Redirect, Route, Switch, Router } from 'react-router-dom'
import classNames from 'classnames'
import EnsureLoggedIn from '../../shared/EnsureLoggedIn'
import DashboardLayout from '../dashboard/components/DashboardContainer'
import InvoiceContainer from '../invoice/components/InvoiceContainer'
import InvoiceItemContainer from '../invoice/components/detail/InvoiceItemContainer'
import LabelContainer from '../label/components/LabelContainer'
import ShippingErrorContainer from '../shippping-errors/components/ShippingErrorContainer'
import RelabelsContainer from '../relabels/components/RelabelsContainer'
import SupplierValidationDetailContainer from '../supplier-validation-detail/components/SupplierValidationDetailContainer'
import getHistory from '../../helpers/routing/getHistory'
import SupplierContainer from '../supplier/components/SupplierContainer'

class Main extends React.Component {
    state = {
        isOpenSidebarMain: false,
        isOpenSidebarMobile: false,
        isCollapsed: true,
    }

    renderRoutes = (routes) => {
        return routes.map((route) => {
            if (route.redirect) {
                return <Redirect key={route.path} from={route.path} to={route.to} />
            }

            if (route.component) {
                return <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
            }

            if (route.items) {
                return this.renderRoutes(route.items)
            }

            return null
        })
    }

    toggleSidebarMain = () => {
        this.setState({
            isOpenSidebarMain: !this.state.isOpenSidebarMain
        })
    }

    toggleSidebarMobile = () => {
        this.setState({
            isOpenSidebarMobile: !this.state.isOpenSidebarMobile
        })
    }

    _toggleSidebarMenu = (e) => {
        e.preventDefault()
        this.setState({
            isCollapsed: !this.state.isCollapsed,
        })
    }

    _collapsedMenu = () => {
        this.setState({
            isCollapsed: true,
        })
    }

    render() {
        const { ...rest } = this.props

        const mainClasses = classNames({
            'Main': true,
            'sidebar-xs': !this.state.isOpenSidebarMain,
            'sidebar-mobile-main': this.state.isOpenSidebarMobile,
            'h-100': true,
        })

        return (
            <div className={mainClasses}>
                <div className='page-content h-100'>
                    <EnsureLoggedIn>
                        <DashboardLayout path={this.props.match.path}>
                            <Router history={getHistory()}>
                                <Switch>
                                    <Route path="/a/invoice-validation" exact component={InvoiceContainer} />
                                    <Route path="/a/invoice-validation/:id" exact component={InvoiceItemContainer} />
                                    <Route path="/a/export-errors-shipping" exact component={ShippingErrorContainer} />
                                    <Route path="/a/relabels" exact component={RelabelsContainer} />
                                    <Route path="/a/supplier-validation" exact component={SupplierContainer} />
                                    <Route path="/a/supplier-validation/:id" exact component={SupplierValidationDetailContainer} />
                                    <Route path="/" exact component={LabelContainer} />
                                </Switch>
                            </Router>
                        </DashboardLayout>
                    </EnsureLoggedIn>
                </div>
            </div>
        )
    }
}

export default Main
