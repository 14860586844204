import api from "../api"
import apiTC from "../apiTC"
import buildQuery from './../../helpers/query/buildQuery'

export const getItemDetail = (idRequest) => {
    return api.makeAuthRequest({
        url: `requests/${idRequest}`,
        method: 'GET',
    })
}

export const getItemDetailTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `requests/${idRequest}`,
        method: 'GET',
    })
}

export const getItemListReports = (request_id, params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `requests/${request_id}/results?${query}`,
        method: 'GET',
    })
}

export const getItemListReportsTC = (request_id, params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `requests/${request_id}/results?${query}`,
        method: 'GET',
    })
}

export const exportReportsCSV = (request_id, params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `requests/${request_id}/export?${query}`,
        method: 'GET',
    })
}

export const exportReportsCSVTC = (request_id, params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `requests/${request_id}/export?${query}`,
        method: 'GET',
    })
}

export const approvedReport = (idRequest) => {
    return api.makeAuthRequest({
        url: `requests/${idRequest}/approve`,
        method: 'POST',
    })
}

export const approvedReportTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `requests/${idRequest}/approve`,
        method: 'POST',
    })
}

export const rejectedReport = (idRequest) => {
    return api.makeAuthRequest({
        url: `requests/${idRequest}/reject`,
        method: 'POST',
    })
}

export const rejectedReportTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `requests/${idRequest}/reject`,
        method: 'POST',
    })
}

export const forceApproveFailedWeight = (idRequest) => {
    return api.makeAuthRequest({
        url: `requests/${idRequest}/force-approve-failed-weight`,
        method: 'POST',
    })
}

export const forceApproveFailedWeightTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `requests/${idRequest}/force-approve-failed-weight`,
        method: 'POST',
    })
}

export const getSupplierItemDetail = (idRequest) => {
    return api.makeAuthRequest({
        url: `suppliers/requests/${idRequest}`,
        method: 'GET',
    })
}

export const getSupplierItemDetailTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `suppliers/requests/${idRequest}`,
        method: 'GET',
    })
}

export const getSupplierItemListReports = (request_id, params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `suppliers/requests/${request_id}/results?${query}`,
        method: 'GET',
    })
}

export const getSupplierItemListReportsTC = (request_id, params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `suppliers/requests/${request_id}/results?${query}`,
        method: 'GET',
    })
}

export const approvedSupplierReport = (idRequest) => {
    return api.makeAuthRequest({
        url: `suppliers/requests/${idRequest}/approve`,
        method: 'POST',
    })
}

export const approvedSupplierReportTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `suppliers/requests/${idRequest}/approve`,
        method: 'POST',
    })
}

export const rejectedSupplierReport = (idRequest) => {
    return api.makeAuthRequest({
        url: `suppliers/requests/${idRequest}/reject`,
        method: 'POST',
    })
}

export const rejectedSupplierReportTC = (idRequest) => {
    return apiTC.makeAuthRequest({
        url: `suppliers/requests/${idRequest}/reject`,
        method: 'POST',
    })
}

export const exportSupplierReportsCSV = (request_id, params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `suppliers/requests/${request_id}/export-results?${query}`,
        method: 'GET',
    })
}

export const exportSupplierReportsCSVTC = (request_id, params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `suppliers/requests/${request_id}/export-results?${query}`,
        method: 'GET',
    })
}
