import React, { Component } from 'react'
import { logoutUser } from '../../../services/AuthServices'

class HeaderApp extends Component {
    logout = (e) => {
        e.preventDefault()
        logoutUser()
    }
    render() {
        return (
            <div className="siteHeader d-flex justify-content-between align-items-center bg-white p-3">
                <h2 className="mb-0">Labels Hub</h2>
                <div className="d-flex">
                    <a href="https://docs.google.com/document/d/1ntztt7SfkYwtTfKzGn_c_KqHHSWggzJgrY7lias3W5A/edit#" rel="noopener noreferrer"><i className="fas fa-life-ring" /> Help</a>
                    <a href='#' className='ml-3' onClick={(e) => this.logout(e)}><i className="fas fa-sign-out" /> Logout</a>
                </div>
            </div>
        )
    }
}

export default HeaderApp