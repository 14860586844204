import api from './../api'
import apiTC from './../apiTC'
import buildQuery from './../../helpers/query/buildQuery'

export const getListLabels = (params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `labels?${query}`,
        method: 'GET',
    })
}

export const getListLabelsTC = (params) => {
    const query = buildQuery(params)

    return apiTC.makeAuthRequest({
        url: `/labels?${query}`,
        method: 'GET',
    })
}