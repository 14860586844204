import React from 'react'
import PropTypes from 'prop-types'
import {
    getUserData,
    isAuthenticated,
    subscribe,
    unsubscribe,
    setAccessToken,
    getAccessToken,
    setRefreshToken, setUserData, setTimeExp, setRoles
} from "../services/AuthServices"
import queryString from "query-string"
import {parseJwt} from './parseToken'

class WithAuthentication extends React.Component {
    constructor(props) {
        super(props)

        const {history} = this.props
        const {location} = history
        let params = {}

        if (location.search !== '') {
            params = queryString.parse(location.search)

            const accessTokenOld = getAccessToken()

            if (Object.keys(params)[1] === 'token' && !accessTokenOld) {
                setAccessToken(params.token)
                const timeExp = parseJwt(params.token).exp - parseJwt(params.token).iat
                setUserData(parseJwt(params.token).permissions)
                setRoles(parseJwt(params.token).roles)
                setTimeExp((timeExp - 300)*1000 + new Date().getTime())
                window.location.href=`${location.pathname}`
            }

            if (Object.keys(params)[0] === 'refresh') {
                setRefreshToken(params.refresh)
            }
        }

        this.state = {
            isAuthenticated: isAuthenticated(),
            user: getUserData()
        }
    }

    componentDidMount() {
        subscribe(this._handleAuthChange)
    }

    componentWillUnmount() {
        unsubscribe(this._handleAuthChange)
    }

    _handleAuthChange = () => {
        this.setState({
            isAuthenticated: isAuthenticated(),
            user: getUserData()
        })
    }

    render() {
        return this.props.children(this.state)
    }
}

WithAuthentication.propTypes = {
    children: PropTypes.func.isRequired,
}

export default WithAuthentication
