import React, { Component } from 'react'
import LabelPage from './LabelPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'

class LabelContainer extends Component {
    state = {
        partner: '1C'
    }

    handleChangePartner = (e) => {
        this.setState({
            partner: e.target.value
        })
    }

    render() {
        const urlParams = parseSearchQuery()
        const { partner } = this.state
        const { page = 1, limit = 50 } = urlParams
        const currentPageParsed = Number(page)
        const currentLimitParsed = Number(limit)
        return (
            // <DashboardLayout path={this.props.match.path}>
            <div className="LabelsContainer mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">List labels</h1>
                    <select name="source" value={partner} onChange={this.handleChangePartner}
                        className="form-control statusFilter partner mb-3" style={{ width: 140, fontWeight: 600 }}>
                        <option value="TC">TOÀN CẦU</option>
                        <option value="1C">BÌNH MINH</option>
                    </select>
                </div>
                <div className="site-layout-background p-3">
                    <LabelPage currentPage={currentPageParsed} currentLimit={currentLimitParsed} partner={partner} />
                </div>
            </div>
            // </DashboardLayout>
        )
    }

}

export default LabelContainer