import React, {Component} from 'react'
import PropTypes from 'prop-types'
import suppliers from "../../../shared/suppliers"

class FilterSupplier extends Component {
    _onHandleChangeInput = (e) => {
        const {onHandleChangeInput} = this.props
        const {name, value} = e.target

        onHandleChangeInput(name, value)
    }

    render() {
        const {
            filter,
            limit,
            total,
            onHandleChangePage,
            onHandleChangeStatus,
            onHandleResetFilter,
            onHandleChangeFilter,
            onHandleClearFilters
        } = this.props
        const {status, filename, supplier} = filter

        return (
            <div className="FilterInvoices mt-1 mb-3 d-md-flex justify-content-between align-items-baseline">
                <div className="FilterStatus d-sm-flex align-items-center mb-md-0 mb-3">
                    <div className="position-relative InputWrapper mr-2">
                        <i className="iconSearch fa fa-search position-absolute"/>
                        <input onChange={this._onHandleChangeInput} name="filename" value={filename}
                               className="form-control mb-sm-0 mb-3" placeholder="Search file name"/>
                        {filename !== '' && (
                            <i onClick={() => onHandleResetFilter('filename')}
                               className="iconClose fa fa-times-circle position-absolute"/>
                        )}
                    </div>
                    <div className="SelectWrap position-relative d-inline-block mr-2">
                        <select value={supplier} onChange={onHandleChangeFilter('supplier')}
                                className="form-control statusFilter">
                            <option value="">All suppliers</option>
                            {
                                suppliers.map(supplier => {
                                    return <option key={supplier.name} value={supplier.name}>{supplier.name}</option>
                                })
                            }
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                    <div className="SelectWrap position-relative d-inline-block">
                        <select value={status} onChange={onHandleChangeStatus} className="form-control statusFilter">
                            <option value="">All status</option>
                            <option value="created">Created</option>
                            <option value="processing">Processing</option>
                            <option value="done">Done</option>
                            <option value="failed">Failed</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                    {
                        (filename || supplier || status) &&
                        <i className="fa fa-refresh ResetInvoiceFilters ml-2" onClick={onHandleClearFilters}/>
                    }
                </div>
                <div className="d-flex align-items-center">
                    <div className="SelectWrap position-relative d-inline-block mr-3 ml-2">
                        <select value={limit} onChange={onHandleChangePage} className="form-control">
                            <option value="5">5 rows</option>
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                    <div className="total"><b>{total} {total === 1 ? 'row' : 'rows'}</b></div>
                </div>
            </div>
        )
    }
}

FilterSupplier.propTypes = {
    filter: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onHandleChangePage: PropTypes.func.isRequired,
    onHandleChangeStatus: PropTypes.func.isRequired,
    onHandleResetFilter: PropTypes.func.isRequired,
}

export default FilterSupplier