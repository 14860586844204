import React, { Component } from 'react'
import InvoiceItemPage from "./InvoiceItemPage"
import DashboardLayout from './../../../dashboard/components/DashboardContainer'

class InvoiceItemContainer extends Component {

    render() {
        const historyState = this.props.location.state
        const vHistoryState = Object.assign({}, historyState)
        const { currentPage = 1, partner = "1C" } = vHistoryState

        return (
            // <DashboardLayout path={this.props.match.path}>
            <InvoiceItemPage currentPage={currentPage} idInvoice={this.props.match.params.id} partner={partner} />
            // </DashboardLayout>
        )
    }

}

export default InvoiceItemContainer