import React, { useState } from 'react'
import SupplierPage from './SupplierPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import CreatedSupplierValidate from "./create/CreatedSupplierValidate"

function SupplierContainer(props) {
    const [partner, setPartner] = useState('1C')
    const urlParams = parseSearchQuery()
    const { page = 1, limit = 20 } = urlParams
    const currentPageParsed = Number(page)
    const currentLimitParsed = Number(limit)

    const handleChangePartner = (e) => {
        const { value } = e.target
        setPartner(value)
    }

    return (
        // <DashboardLayout path={props.match.path}>
        <div className="InvoiceContainer mt-3">
            <div className="d-sm-flex justify-content-between align-items-center">
                <h1 className="PageTitle mb-3">Supplier validation</h1>
                <div className="d-sm-flex justify-content-between align-items-center">
                    <CreatedSupplierValidate filters={urlParams} partner={partner} />
                    <select
                        name="source"
                        value={partner}
                        onChange={handleChangePartner}
                        className="form-control statusFilter partner mb-3 ml-3"
                        style={{ width: 140, fontWeight: 600 }}
                    >
                        <option value="TC">TOÀN CẦU</option>
                        <option value="1C">BÌNH MINH</option>
                    </select>
                </div>
            </div>
            <div className="site-layout-background p-3">
                <SupplierPage
                    filters={urlParams}
                    currentPage={currentPageParsed}
                    currentLimit={currentLimitParsed}
                    partner={partner}
                />
            </div>
        </div>
        // </DashboardLayout>
    )
}

export default SupplierContainer
