import React, { Component } from 'react'
import ShippingErrorPage from './ShippingErrorPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"

class ShippingErrorContainer extends Component {
    state = {
        partner: '1C'
    }

    handleChangePartner = (e) => {
        this.setState({
            partner: e.target.value
        })
    }

    render() {
        const { partner } = this.state
        return (
            // <DashboardLayout path={this.props.match.path}>
            <div className="ExportErrorShipping mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">Export error shipping</h1>
                    <select name="source" value={partner} onChange={this.handleChangePartner}
                        className="form-control statusFilter partner mb-3" style={{ width: 140, fontWeight: 600 }}>
                        <option value="TC">TOÀN CẦU</option>
                        <option value="1C">BÌNH MINH</option>
                    </select>
                </div>
                <div className="site-layout-background p-4">
                    <ShippingErrorPage partner={partner} />
                </div>
            </div>
            // </DashboardLayout>
        )
    }

}

export default ShippingErrorContainer