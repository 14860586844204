import React, {Component} from 'react'
import ConfirmModal from './ConfirmModal'
import { Tooltip } from 'reactstrap'
import PropTypes from "prop-types"
import PubSub from "../../../../../node_modules/pubsub.js/pubsub"
import {rejectSupplierValidation, rejectSupplierValidationTC} from "../../../../services/api/SupplierValidationServices"

class ActionReject extends Component {
    state = {
        loading: false,
        error: '',
        isOpen: false,
        tooltipOpen: false
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    _setTooltipOpen = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    _handleSubmit = async () => {
        const {requestID, partner} = this.props

        this.setState({
            loading: true,
            error: ''
        })

        const {success, message} = partner === "1C" ? await rejectSupplierValidation(requestID) : await rejectSupplierValidationTC(requestID)

        if(!success) {
            return this.setState({
                loading: false,
                error: message
            })
        }

        return this.setState({
            error: '',
            loading: false
        }, () => {
            this._handleToggleModal()
            PubSub.publish('LOAD_LIST_SUPPLIER')
            PubSub.publish('LOAD_DETAIL_SUPPLIER')
        })

    }

    render() {
        const {isOpen, tooltipOpen, loading, error} = this.state
        const {requestID, requestNumber, isDetail} = this.props

        return (
            <div>
                {isDetail ? (
                    <div>
                        <button className="btn btn-danger btn-sm ml-3" onClick={this._handleToggleModal}>
                            <i className="fas fa-times-circle mr-1"/> Reject
                        </button>
                    </div>
                ) : (
                    <div>
                        <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipReject" toggle={this._setTooltipOpen}>
                            Reject request
                        </Tooltip>
                        <i id="TooltipReject" className="fas fa-times-circle text-danger ml-1" onClick={this._handleToggleModal} />
                    </div>
                )}

                {isOpen && (
                    <ConfirmModal
                        requestID={requestID}
                        requestNumber={requestNumber}
                        action="reject"
                        isOpen={isOpen}
                        loading={loading}
                        error={error}
                        onToggleModal={this._handleToggleModal}
                        handleSubmit={this._handleSubmit}
                    />
                )}
            </div>
        )
    }
}

ActionReject.propTypes = {
    requestID: PropTypes.string.isRequired,
    isDetail: PropTypes.bool.isRequired,
    requestNumber: PropTypes.string.isRequired
}

export default ActionReject