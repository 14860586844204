import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {INVOICE_CARRIERS} from "../../../../helpers/consts"

const fileStatuses = [
    {
        value: 'done',
        label: 'Done'
    },
    {
        value: 'failed',
        label: 'Failed'
    }
]

class TopTable extends Component {
    _handleChangeLimit = (e) => {
        const {value} = e.target
        const {onChangeLimit} = this.props

        if (typeof onChangeLimit === 'function') {
            onChangeLimit(value)
        }
    }

    setFilter = (prop) => (e) => {
        this.props.setFilter(prop, e.target.value)
    }

    reload = () => this.props.reload()

    render() {
        const {limit, total} = this.props

        return (
            <div className="TopTable d-flex align-items-center justify-content-end mb-3">
                <div className="mr-4">
                    <select className="form-control" onChange={this.setFilter('carrier')}>
                        <option value="">All carriers</option>
                        {
                            INVOICE_CARRIERS.map(carrier => {
                                return <option value={carrier.value}>{carrier.label}</option>
                            })
                        }
                    </select>
                </div>
                <div className="mr-4">
                    <select className="form-control" onChange={this.setFilter('status')}>
                        <option value="">All status</option>
                        {
                            fileStatuses.map(status => {
                                return <option value={status.value}>{status.label}</option>
                            })
                        }
                    </select>
                </div>
                <div className="mr-4">
                    <button className="btn btn-outline-secondary" onClick={this.reload}><i className="fas fa-refresh"/>
                    </button>
                </div>
                <div className="total">Total <b>{total}</b> {total === 1 ? 'item' : 'items'}</div>
                <div className="SelectWrap position-relative d-inline-block ml-3">
                    <select value={limit} onChange={this._handleChangeLimit} className="form-control">
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                    </select>
                    <i className="fa fa-caret-down position-absolute"/>
                </div>
            </div>
        )
    }
}

TopTable.propTypes = {
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onChangeLimit: PropTypes.func.isRequired
}

export default TopTable