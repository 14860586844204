import React, {Component} from 'react'
import LabelColValue from './LabelColValue'
import moment from 'moment'
import humanizeTime from "../../../../helpers/time/humanizeTime"
import LabelColTrackingNumber from './LabelColTrackingNumber'
import PropTypes from "prop-types"
import ModalInformation from "../ModalInformation"

class LabelRow extends Component {

    _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="badge badge-success">Done</span>
            case 'failed':
                return <span className="badge badge-danger">Failed</span>
            case 'changed':
                return <span className="badge badge-warning">Changed</span>
            default:
                return
        }
    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {request} = item

        return (
            <tr>
                <LabelColValue value={item.order_number}/>
                <LabelColValue value={this._getStatus(item.status)}/>
                <LabelColValue value={this._validateDate(item.created)}/>
                <LabelColTrackingNumber tracking={item.labelDetails || (request && request.responseData) || ''} partner={false}/>
                <LabelColTrackingNumber tracking={item.labelDetails || (request && request.responseData) || ''} partner={true}/>
                <LabelColValue value={item.shipping_carrier || ''}/>
                <LabelColValue value={request !== undefined ? request.country : ''}/>
                <LabelColValue value={request !== undefined ? request.weight : ''}/>
                <LabelColValue value={item.source || ''}/>
                <td>
                    <ModalInformation item={item}/>
                </td>
            </tr>
        )
    }
}

LabelRow.propTypes = {
    item: PropTypes.object.isRequired
}

export default LabelRow
