import React, {Component} from 'react'
import PropTypes from "prop-types"

class LabelColValue extends Component {
    render() {
        const {value} = this.props

        return (
            <td>{value}</td>
        )
    }
}

LabelColValue.defaultProps = {
    value: ''
}

LabelColValue.propTypes = {
    value: PropTypes.any
}

export default LabelColValue