import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/_index.scss'
import { Router, Route, Switch } from 'react-router-dom'
import InvoiceContainer from './app/invoice/components/InvoiceContainer'
import InvoiceItemContainer from './app/invoice/components/detail/InvoiceItemContainer'
import LabelContainer from './app/label/components/LabelContainer'
import ShippingErrorContainer from './app/shippping-errors/components/ShippingErrorContainer'
import RelabelsContainer from './app/relabels/components/RelabelsContainer'
import SupplierValidationDetailContainer
    from "./app/supplier-validation-detail/components/SupplierValidationDetailContainer"
import getHistory from "./helpers/routing/getHistory"
import SupplierContainer from './app/supplier/components/SupplierContainer'
import LoginContainer from './app/Login/components/LoginContainer'
import Main from './app/Main/Main'
import { getLocalData } from '@pf126/api-maker/src/StorageServices'

const App = (props) => {
    const tokenData = getLocalData('accessToken')
    const [token, setToken] = useState(tokenData)

    const config = {
        siteName: 'Label-Hub',
        tagLine: 'Label-Hub'
    };

    const _setToken = (token) => {
        setToken(token)
    }

    useEffect(() => {
        document.title = config.siteName
    }, [])
    return (

        <div className="App h-100">
            <Switch>
                <Route exact path="/login">
                    <LoginContainer
                        onChangeToken={_setToken}
                    />
                </Route>
                <Main {...props} />
            </Switch>
        </div>
    )
}

export default App
