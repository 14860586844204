import React, {Component} from 'react'
import TableLoading from "../../../../shared/TableLoading"
import {getListRelabels, downloadRelabel, getListRelabelsTC, downloadRelabelTC} from "../../../../services/api/RelabelServices"
import PubSub from "pubsub.js";
import moment from 'moment'
import humanizeTime from "../../../../helpers/time/humanizeTime"

class RelabelsTable extends Component {
    state = {
        data: [],
        page: 1,
        pages: 1,
        limit: 20,
        total: 0,
        error: '',
        loading: false
    }

    componentDidMount() {
        this._handleGetListRelabels()
        this._token = PubSub.subscribe('LOAD_LIST_RELABELS', () => {
            this._handleGetListRelabels()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.partner !== this.props.partner) {
            this.setState({
                page: 1
            }, this._handleGetListRelabels)
        }
    }

    componentWillUnmount() {
        this._token && PubSub.unsubscribe(this._token)
    }

    _handleGetListRelabels = async () => {
        try {
            this.setState({
                loading: true,
                error: '',
                data: []
            })
            const {partner} = this.props
            const {data, message, success} = partner === "1C" ? await getListRelabels() : await getListRelabelsTC()

            if(!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                data: data,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }

    }

    _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="badge badge-success">Done</span>
            case 'created':
                return <span className="badge badge-primary">Created</span>
            case 'processing':
                return <span className="badge badge-warning">Processing</span>
            default:
                return <span className="badge badge-danger">Failed</span>
        }
    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    _handleDownloadRelabel = async (id) => {
        try {
            const {partner} = this.props
            window.location.href = partner === "1C" ? downloadRelabel(id) : downloadRelabelTC(id)
        } catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    render() {
        const {error, data, loading} = this.state

        return (
            <>
                {error && <div className="text-danger mb-3">{error}</div>}
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th className="colOrderNumber">File name</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th></th>
                    </tr>
                    </thead>
                    {
                        loading ?
                            <TableLoading columnQuantity={4}/>
                            :
                            <tbody>
                            {data.length > 0 ? (
                                data.map(item => {
                                    return (
                                        <tr key={item._id}>
                                            <td>
                                                {item.request_number}
                                            </td>
                                            <td>
                                                {this._getStatus(item.status)}
                                            </td>
                                            <td>
                                                {this._validateDate(item.created)}
                                            </td>
                                            <td><button onClick={() => this._handleDownloadRelabel(item._id)} type="button" class="btn btn-link">Download file</button></td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>No results</td>
                                </tr>
                            )}
                            </tbody>
                    }
                </table>
            </>
        )
    }

}

export default RelabelsTable