import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import SupplierValidationResult from "./SupplierValidationResult"
import {getSupplierItemDetail, getSupplierItemDetailTC} from "../../../services/api/InvoiceValidationServices"
import moment from "moment"
import humanizeTime from "../../../helpers/time/humanizeTime"
import ActionApprove from "../components/action/ActionApprove"
import ActionReject from "../components/action/ActionReject"
import suppliersConfig from '../../../shared/suppliers'

SupplierValidationDetailPage.propTypes = {
    idValidation: PropTypes.string.isRequired,
    currentPage: PropTypes.number
}

function SupplierValidationDetailPage(props) {
    const {idValidation, currentPage, partner} = props
    const [error, setError] = useState('')
    const [item, setItem] = useState({})

    const _fetchDetailSupplier = async () => {
        setError('')
        setItem({})

        try {
            const {data, success, message} = partner === "1C" ? await getSupplierItemDetail(idValidation) : await getSupplierItemDetailTC(idValidation)

            if (!success) {
                return setError(message)
            }

            setItem(data)
        } catch (e) {
            setError(e.message)
        }
    }

    const _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="mr-2 badge badge-success">Done</span>
            case 'created':
                return <span className="mr-2 badge badge-secondary">Created</span>
            case 'processing':
                return <span className="mr-2 badge badge-warning">Processing</span>
            default:
                return <span className="mr-2 badge badge-danger">Failed</span>
        }
    }

    const _getApprovedStatus = (status) => {
        switch (status) {
            case 'rejecting':
                return <span className="badge badge-secondary">Rejecting</span>
            case 'rejected':
                return <span className="badge badge-danger">Rejected</span>
            case 'approving':
                return <span className="badge badge-secondary">Approving</span>
            case 'approved':
                return <span className="badge badge-success">Approved</span>
            default:
                return
        }
    }

    const _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    useEffect(() => {
        _fetchDetailSupplier()
        // eslint-disable-next-line
    }, [])

    const backUrl = currentPage === 1 ? '/a/supplier-validation' : `/a/supplier-validation?page=${currentPage}`
    const {results_statuses_count} = item
    const supplierConfig = suppliersConfig.find(it => it.name === item.partner)
    const {done: doneResult = 0, failed: failedResult = 0} = {...results_statuses_count}

    return (
        <div className='SupplierValidationDetailPage'>
            <div className="d-flex justify-content-between align-items-end">
                <div className="HeadPage">
                    <div className="BackPage mb-3">
                        <Link to={backUrl}>
                            <i className="fas fa-chevron-left mr-1"/> Supplier validation
                        </Link>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <h3 className="mb-0 mr-3 PageTitle">{item.filename} (#{item.request_number})</h3>
                        <div className="mb-0 badge badge-primary mr-2"
                             style={supplierConfig ? {backgroundColor: supplierConfig.color} : {}}>{item.partner}</div>
                        {item.status && _getStatus(item.status)}
                    </div>
                </div>

                {(item._id && item.approval_status === undefined) && (
                    <div className="action d-flex align-items-center mb-3">
                        <ActionApprove
                            getDetailSupplier={_fetchDetailSupplier}
                            requestID={item._id}
                            requestNumber={item.request_number}
                            isDetail={true}
                            partner={partner}
                        />
                        <ActionReject
                            getDetailSupplier={_fetchDetailSupplier}
                            requestID={item._id}
                            requestNumber={item.request_number}
                            isDetail={true}
                            partner={partner}
                        />
                    </div>
                )}
            </div>

            <div className="site-layout-background p-3">
                {item.filename !== undefined && (
                    <div>
                        {error && (
                            <div className="text-danger mb-3">{error}</div>
                        )}
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="rowInfoInvoice d-flex px-3">
                                    <label>File name: </label> {item.filename}
                                </div>
                                <div className="rowInfoInvoice px-3">
                                    <label>Request number: </label> {item.request_number}
                                </div>
                                {(item.approval_status !== undefined && item.approval_status !== '') && (
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>Approve status: </label>
                                        <div>{_getApprovedStatus(item.approval_status)}</div>
                                    </div>
                                )}
                                <div className="rowInfoInvoice d-flex px-3">
                                    <label>Note: </label> {item.note}
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="rowInfoInvoice d-flex px-3">
                                    <label>Created: </label> {_validateDate(item.created)}
                                </div>
                                <div className="rowInfoInvoice d-flex px-3">
                                    <label>Finished at: </label> {_validateDate(item.finished_at)}
                                </div>
                                <div className="rowInfoInvoice d-flex px-3">
                                    <label>Results statuses: </label>
                                    <div>
                                        <span className="mr-3">
                                            <span className="badge badge-success mr-1">
                                                Done
                                            </span>
                                            <span>{doneResult} {doneResult > 1 ? 'records' : 'record'}</span>
                                        </span>
                                        <span className="mr-3">
                                            <span className="badge badge-danger mr-1">
                                                Failed
                                            </span>
                                            <span>{failedResult} {failedResult > 1 ? 'records' : 'record'}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <SupplierValidationResult
                    idValidation={idValidation}
                    filename={item.filename}
                    status={item.status}
                    partner={partner}
                />
            </div>
        </div>
    )
}

export default SupplierValidationDetailPage
