import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PubSub from 'pubsub.js'
import {createInvoiceValidation, createInvoiceValidationTC} from "../../../../services/api/CreateInvoiceServices"
import {Form, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"

CreatedInvoiceValidateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired
}

function CreatedInvoiceValidateModal(props) {
    const format = /^[a-zA-Z0-9-_. ]*$/;

    const [formValidate, _setFile] = useState({
        file: '',
        filename: '',
        note: '',
        error: '',
        validate: {
            file: '',
            filename: ''
        },
        loading: false,
        shippingCarrier: 'multrans_us'
    })

    function _validate() {
        const {file, filename} = formValidate

        _setFile({
            ...formValidate,
            validate: {
                file: file === '' ? 'Vui lòng chọn file CSV.' : '',
                filename: filename.trim() === '' ? 'Vui lòng nhập tên file.' : !format.test(filename) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : ''
             }
        })
    }

    function _onChangeFile(e) {
        const {files} = e.target

        if (files[0]) {
            const filename = files[0].name.replace(/\.[^.]+$/, '')
            const extension = files[0].name.replace(/.+\.(?=[^.]+$)/, '')

            if(files[0].size > 5 * 1024 * 1024) {
                return _setFile({
                    ...formValidate,
                    validate: {
                        ...formValidate.validate,
                        filename: !format.test(removeAscent(filename)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                        file: 'File giới hạn kích thước 5MB. Vui lòng kiểm tra lại.'
                    },
                    error: '',
                })
            }

            if (extension !== 'csv' && files[0].name !== 'csv') {
                return _setFile({
                    ...formValidate,
                    validate: {
                        ...formValidate.validate,
                        filename: !format.test(removeAscent(filename)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                        file: 'File upload định dạng CSV. Vui lòng kiểm tra lại.'
                    },
                    error: '',
                })
            }

            _setFile({
                ...formValidate,
                file: files[0],
                filename: removeAscent(filename),
                validate: {
                    ...formValidate.validate,
                    filename: !format.test(removeAscent(filename)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                    file: ''
                },
                error: '',
            }, () => {
                _validate()
            })
        }
    }

    function _onToggle() {
        const {onToggleModal} = props

        if (typeof onToggleModal === 'function') {
            onToggleModal()
        }
    }

    function removeAscent (str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ",
            " $"
        ];
        for (var i=0; i<AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }


    function _handleChangeField(e) {
        const {name, value} = e.target

        if (name === 'filename') {

            _setFile({
                ...formValidate,
                [name]: removeAscent(value),
                validate: {
                    ...formValidate.validate,
                    filename: value.trim() === '' ? 'Vui lòng nhập tên file.' : !format.test(removeAscent(value)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : ''
                }
            })
        } else {
            _setFile({
                ...formValidate,
                [name]: value
            })
        }
    }

    function _handleChangeCarrier(e) {
        const {value} = e.target

        _setFile({
            ...formValidate,
            shippingCarrier: value
        })
    }

    async function _onSubmit() {
        const {partner} = props
        const {file, note, filename, shippingCarrier} = formValidate
        const formData = new FormData()

        formData.append('invoice', file)
        formData.append('note', note)
        formData.append('filename', filename.trim())
        formData.append('shipping_carrier', shippingCarrier)

        if (!file || !format.test(removeAscent(filename))) {
            _validate()
            return
        }

        _setFile({...formValidate, loading: true})

        const {success, message} = partner === "1C" ? await createInvoiceValidation(formData) : await createInvoiceValidationTC(formData)

        if (!success) {
            return _setFile({...formValidate, loading: false, error: message})
        }

        _onToggle()
        PubSub.publish('LOAD_LIST_INVOICE')
        _setFile({...formValidate, loading: false})
    }

    const {isOpen} = props
    const {loading, error, validate, filename, note, shippingCarrier} = formValidate
    const disableUploadBtn = loading || !!validate.file || !!validate.filename

    return (
        <Modal isOpen={isOpen} className="CreateInvoiceModal">
            <ModalHeader toggle={_onToggle}>Create invoice validation</ModalHeader>

            <ModalBody>
                <Form>
                    <div className="form-group">
                        <label>Upload file CSV</label>
                        <input
                            type="file"
                            accept=".csv"
                            className="form-control-file"
                            onChange={_onChangeFile}
                        />

                        {
                            validate.file && <div className="text-danger mt-2">{validate.file}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>File name</label>
                        <input
                            type="text"
                            name='filename'
                            value={filename}
                            className="form-control"
                            onChange={_handleChangeField}
                        />

                        {
                            validate.filename && <div className="text-danger mt-2">{validate.filename}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Shipping carrier</label>
                        <Input
                            type="select"
                            className="form-control"
                            value={shippingCarrier}
                            onChange={_handleChangeCarrier}
                        >
                            <option value='hw_usps'>HW_USPS</option>
                            <option value='multrans_us'>Multrans US</option>
                            <option value='multrans_us_fast'>Multrans US Fast</option>
                            <option value='multrans_eu'>Multrans EU</option>
                            <option value='shippo_us'>Shippo US</option>
                            <option value='hw_yun'>HW_YUN</option>
                            <option value="zone9_us">Zone9 US</option>
                            <option value="zone9_ca">Zone9 CA</option>
                        </Input>
                    </div>

                    <div className="form-group">
                        <label>Note</label>
                        <textarea
                            name='note'
                            className="form-control"
                            defaultValue={note}
                            onChange={_handleChangeField}
                        />
                    </div>
                </Form>

                {
                    error && <div className="text-danger mt-3">{error}</div>
                }
            </ModalBody>
            <ModalFooter>
                <div className="ModalButtons">
                    <button className="btn btn-light border mr-2" onClick={_onToggle}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={_onSubmit} disabled={disableUploadBtn}>
                        {loading ? <span>Uploading...</span> : 'Upload'}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CreatedInvoiceValidateModal
