export const INVOICE_CARRIERS = [
    {
        value: 'multrans_us',
        label: 'Multrans US'
    },
    {
        value: 'hw_usps',
        label: 'HW_USPS'
    },
    {
        value: 'hw_yun',
        label: 'HW_YUN'
    },
    {
        value: 'multrans_us_fast',
        label: 'Multrans US Fast'
    },
    {
        value: 'multrans_eu',
        label: 'Multrans EU'
    },
    {
        value: 'shippo_us',
        label: 'Shippo US'
    },
    {
        value: 'hw_tiktok',
        label: 'HW TIKTOK'
    },
    { value: 'zone9_us', label: 'Zone9 US' },
    { value: 'zone9_ca', label: 'Zone9 CA' },
]