import api from './../api'
import apiTC from './../apiTC'


export const createRelabel = (data) => {
    return api.makeAuthRequest({
        url: `change-labels`,
        method: 'POST',
        data
    })
}

export const createRelabelTC = (data) => {
    return apiTC.makeAuthRequest({
        url: `change-labels`,
        method: 'POST',
        data
    })
}

export const getListRelabels = () => {
    return api.makeAuthRequest({
        url: `change-label-requests`,
        method: 'GET'
    })
}

export const getListRelabelsTC = () => {
    return apiTC.makeAuthRequest({
        url: `change-label-requests`,
        method: 'GET'
    })
}

export const downloadRelabel = (id) => {
    const baseUrl = api.getBaseURL()

    return `${baseUrl}/change-label-requests/${id}/uploaded-file`
}

export const downloadRelabelTC = (id) => {
    const baseUrl = apiTC.getBaseURL()

    return `${baseUrl}/change-label-requests/${id}/uploaded-file`
}