import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ObjectInspector from "react-object-inspector"
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"

class ModalInformation extends Component {
    state = {
        isOpen: false
    }


    _toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const {isOpen} = this.state
        const {item} = this.props

        return (
            <div className="ModalInformation">
                <Button color="link" onClick={this._toggle}>Detail</Button>
                <Modal isOpen={isOpen} toggle={this._toggle} className='ModalCustom'>
                    <ModalHeader toggle={this._toggle}>Information</ModalHeader>
                    <ModalBody>
                        <h6 className='mb-3'>Information Label</h6>
                        <ObjectInspector depth={0} path="root.value" data={item}/>
                    </ModalBody>
                    <ModalFooter className='text-right'>
                        <Button color="secondary" onClick={this._toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ModalInformation.propTypes = {
    item: PropTypes.object
}

export default ModalInformation