import React, {Component} from 'react'
import PropTypes from 'prop-types'

class StatusFile extends Component {
    render() {
        const {status} = this.props

        return (
            <td className='StatusFile'>
                <span className={`status ${status}`}>{status === 'created' ? 'pending' : status}</span>
            </td>
        )
    }
}

StatusFile.propTypes = {
    status: PropTypes.string
}

export default StatusFile