import React, { Component } from 'react'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import RelabelsPage from './RelabelsPage'
import CreateRelabel from './create/CreateRelabel'

class RelabelsContainer extends Component {
    state = {
        partner: '1C'
    }

    handleChangePartner = (e) => {
        this.setState({
            partner: e.target.value
        })
    }

    render() {
        const { partner } = this.state
        return (
            // <DashboardLayout path={this.props.match.path}>
            <div className="RelabelsContainer mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">Relabels</h1>
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <CreateRelabel partner={partner} />
                        <select name="source" value={partner} onChange={this.handleChangePartner}
                            className="form-control statusFilter partner mb-3 ml-3" style={{ width: 140, fontWeight: 600 }}>
                            <option value="TC">TOÀN CẦU</option>
                            <option value="1C">BÌNH MINH</option>
                        </select>
                    </div>
                </div>
                <div className="site-layout-background p-3">
                    <RelabelsPage partner={partner} />
                </div>
            </div>
            // </DashboardLayout>
        )
    }
}

export default RelabelsContainer