import React, {Component} from 'react'
import FileErrorListPage from "./FileErrorListPage"

class FileErrorListContainer extends Component {
    render() {
        const {partner} = this.props
        return (
            <div className='FileErrorListContainer'>
                <FileErrorListPage partner={partner}/>
            </div>
        )
    }
}

export default FileErrorListContainer