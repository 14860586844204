import React, {Component} from 'react'
import PropTypes from 'prop-types'


class FilterLabel extends Component {
    _onHandleChangeInput = (e) => {
        const {onHandleChangeInput} = this.props
        const {name, value} = e.target

        onHandleChangeInput(name, value)
    }

    render() {
        const {filter, limit, total, onHandleChangePage, onHandleChangeStatus, onHandleResetFilter, shippingCarriers} = this.props
        const {status, order_number} = filter

        return (
            <div className="FilterLabels mt-1 mb-3">
                <div className="row FilterStatus">
                    <div className="col-2">
                        <div className="position-relative InputWrapper mb-3">
                            <label>Order number</label>
                            <i className="iconSearch fa fa-search position-absolute"/>
                            <input onChange={this._onHandleChangeInput} name="order_number" value={order_number}
                                   className="form-control mb-sm-0 mb-3" placeholder="Input order number"/>
                            {order_number !== '' && (
                                <i onClick={() => onHandleResetFilter('order_number')}
                                   className="iconClose fa fa-times-circle position-absolute"/>
                            )}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="position-relative InputWrapper mb-3">
                            <label>Partner tracking number</label>
                            <i className="iconSearch fa fa-search position-absolute"/>
                            <input onChange={this._onHandleChangeInput} name="partner_tracking_number"
                                   value={filter.partner_tracking_number} className="form-control"
                                   placeholder="Input tracking number"/>
                            {filter.partner_tracking_number && (
                                <i onClick={() => onHandleResetFilter('partner_tracking_number')}
                                   className="iconClose fa fa-times-circle position-absolute"/>
                            )}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="position-relative InputWrapper mb-3">
                            <label>Tracking number</label>
                            <i className="iconSearch fa fa-search position-absolute"/>
                            <input onChange={this._onHandleChangeInput} name="tracking_number"
                                   value={filter.tracking_number} className="form-control"
                                   placeholder="Input tracking number"/>
                            {filter.tracking_number !== '' && (
                                <i onClick={() => onHandleResetFilter('tracking_number')}
                                   className="iconClose fa fa-times-circle position-absolute"/>
                            )}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="SelectWrap position-relative mb-3">
                            <label>Shipping carrier</label>
                            <select name="shipping_carrier" value={filter.shipping_carrier}
                                    onChange={this._onHandleChangeInput} className="form-control shippingFilter">
                                <option value="">All</option>
                                {
                                    shippingCarriers.length > 0 && shippingCarriers.map((shippingCarrier, index) => {
                                        return <option key={index} value={shippingCarrier.value}>{shippingCarrier.name}</option>
                                    })
                                }
                            </select>
                            <i className="fa fa-caret-down position-absolute"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="SelectWrap position-relative mb-3">
                            <label>Source</label>
                            <select name="source" value={filter.source} onChange={this._onHandleChangeInput}
                                    className="form-control statusFilter">
                                <option value="">All</option>
                                <option value="VN">VN</option>
                                <option value="US">US</option>
                            </select>
                            <i className="fa fa-caret-down position-absolute"/>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="SelectWrap position-relative mb-3">
                            <label>Status</label>
                            <select name="status" value={status} onChange={onHandleChangeStatus}
                                    className="form-control statusFilter">
                                <option value="">All</option>
                                <option value="done">Done</option>
                                <option value="failed">Failed</option>
                            </select>
                            <i className="fa fa-caret-down position-absolute"/>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                    <div className="total"><b>{total} {total === 1 ? 'item' : 'items'}</b></div>
                    <div className="SelectWrap position-relative d-inline-block ml-3">
                        <select value={limit} onChange={onHandleChangePage} className="form-control">
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                </div>
            </div>
        )
    }
}

FilterLabel.propTypes = {
    filter: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onHandleChangePage: PropTypes.func.isRequired,
    onHandleChangeStatus: PropTypes.func.isRequired,
    onHandleResetFilter: PropTypes.func.isRequired,
}

export default FilterLabel