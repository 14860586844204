import React, { Component } from 'react'
import WithAuthentication from './WithAuthentication'
import { withRouter } from "react-router-dom"
import createApiServices from '../services/api/createApiServices'
import { logoutUser } from "../services/AuthServices"
import { getLocalData } from '@pf126/api-maker/src/StorageServices'
import { refreshToken } from "../services/api/UserService"
import { parseJwt } from "./parseToken"
import * as AuthServices from "../services/AuthServices"
import redirect from "./redirect"

class EnsureLoggedIn extends Component {
    state = {
        load: false
    }
    _api = createApiServices()

    componentWillMount() {
        const timeExp = getLocalData('timeExp')

        if (new Date().getTime() > timeExp) {
            this._handleRefreshToken()
        } else {
            this.setState({
                load: true
            })
        }
        this._api.subscribeErrorRequest(error => {
            const { status, data } = error
            if (status === 403 || status === 401) {
                const { message } = Object.assign({}, data)
                logoutUser(message || '')
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({ load: false })

        const timeExp = getLocalData('timeExp')

        if (new Date().getTime() > timeExp) {
            this._handleRefreshToken()
        } else {
            this.setState({
                load: true
            })
        }

        this._api.subscribeErrorRequest(error => {
            const { status, data } = error
            if (status === 403 || status === 401) {
                const { message } = Object.assign({}, data)
                logoutUser(message || '')
            }
        })
    }

    _handleRefreshToken = async () => {
        const { history } = this.props
        const { location } = history
        const _refreshToken = getLocalData('refreshToken')
        try {
            const payload = {
                refreshToken: _refreshToken
            }

            const { data, success, message } = await refreshToken(payload)

            if (!success) {
                logoutUser(message || '')
                return redirect(`/login`)
            }

            const { accessToken, roles, refreshToken: refreshTok, permissions } = data

            const timeExp = parseJwt(accessToken).exp - parseJwt(accessToken).iat

            AuthServices.setAccessToken(accessToken)
            AuthServices.setUserData(permissions)
            AuthServices.setRoles(roles)
            AuthServices.setRefreshToken(refreshTok)
            AuthServices.setTimeExp((timeExp - 300) * 1000 + new Date().getTime())

            window.location.href = `${location.pathname}`

            this.setState({
                load: true
            })
        } catch (e) {
            logoutUser(e.message || '')
            redirect(`/login`)
        }
    }

    render() {
        const { load } = this.state
        const { location } = this.props

        return (
            <WithAuthentication {...this.props}>
                {
                    ({ isAuthenticated }) => {
                        if (load) {
                            if (isAuthenticated) {
                                return this.props.children
                            } else {
                                redirect(`/login`)
                                return
                            }
                        } else {
                            return <div className="text-center">Đang tải...</div>
                        }
                    }
                }
            </WithAuthentication>
        )
    }
}

export default withRouter(EnsureLoggedIn)
