import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'
import PropTypes from "prop-types"

class ConfirmModal extends Component {

    _onSubmit = async () => {
        const {handleSubmit} = this.props

        handleSubmit()
    }

    _onToggle = () => {
        this.props.onToggleModal()
    }

    render() {
        const {loading, error, requestNumber, action, isOpen, onToggleModal} = this.props

        return (
            <Modal isOpen={isOpen} className="ActionModal">
                <ModalHeader toggle={onToggleModal}>Confirm {action}</ModalHeader>

                <ModalBody>
                    Are you sure to {action} request <b>{requestNumber}</b>?
                    {error && (
                        <div className="text-danger mt-3">{error}</div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="ModalButtons">
                        <button className="btn btn-light border mr-2" onClick={this._onToggle}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this._onSubmit} disabled={loading}>
                            {loading ? <span>{action === 'reject' ? 'Rejecting' : 'Approving'}...</span> : action}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    requestID: PropTypes.string.isRequired,
    requestNumber: PropTypes.string.isRequired,
    onToggleModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default ConfirmModal