import React, {Component} from 'react'
import ConfirmModal from './ConfirmModal'
import {Tooltip} from 'reactstrap'
import PropTypes from "prop-types"
import {approvedSupplierReport, approvedSupplierReportTC} from '../../../../services/api/InvoiceValidationServices'

class ActionApprove extends Component {
    state = {
        loading: false,
        error: '',
        isOpen: false,
        tooltipOpen: false
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    _setTooltipOpen = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    _handleSubmit = async () => {
        const {requestID, partner} = this.props

        this.setState({
            loading: true,
            error: ''
        })

        const {success, message} = partner === "1C" ? await approvedSupplierReport(requestID) : await approvedSupplierReportTC(requestID)

        if (!success) {
            return this.setState({
                loading: false,
                error: message
            })
        }

        return this.setState({
            error: '',
            loading: false
        }, () => {
            const {getDetailSupplier} = this.props

            this._handleToggleModal()
            getDetailSupplier()
        })

    }

    render() {
        const {isOpen, tooltipOpen, loading, error} = this.state
        const {requestID, requestNumber, isDetail} = this.props

        return (
            <div>
                {isDetail ? (
                    <div>
                        <button className="btn btn-primary btn-sm ml-3" onClick={this._handleToggleModal}>
                            <i className="fas fa-check mr-1"/> Approve
                        </button>
                    </div>
                ) : (
                    <div>
                        <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipApprove"
                                 toggle={this._setTooltipOpen}>
                            Approve request
                        </Tooltip>
                        <i id="TooltipApprove" className="fas fa-check text-success mr-1"
                           onClick={this._handleToggleModal}/>
                    </div>
                )}

                {isOpen && (
                    <ConfirmModal
                        requestID={requestID}
                        requestNumber={requestNumber}
                        action="approve"
                        isOpen={isOpen}
                        loading={loading}
                        error={error}
                        onToggleModal={this._handleToggleModal}
                        handleSubmit={this._handleSubmit}
                    />
                )}
            </div>
        )
    }
}

ActionApprove.propTypes = {
    requestID: PropTypes.string.isRequired,
    isDetail: PropTypes.bool.isRequired,
    requestNumber: PropTypes.string.isRequired
}

export default ActionApprove
