import React, {Component} from 'react'
import PropTypes from "prop-types"

class LabelColTrackingNumber extends Component {
    render() {
        const {tracking, partner} = this.props

        return (
            <td>
                {
                    tracking && partner ? (
                        <span>{tracking.trackingNumber} <a className="ml-1"
                                                           target="_blank"
                                                           rel="noopener noreferrer"
                                                           href={tracking.url}><i
                            className="fas fa-external-link text-primary"></i></a></span>
                    ) : tracking.partnerTrackingNumber
                }
            </td>
        )
    }
}

LabelColTrackingNumber.defaultProps = {
    tracking: ''
}

LabelColTrackingNumber.propTypes = {
    tracking: PropTypes.any,
    partner: PropTypes.bool.isRequired
}

export default LabelColTrackingNumber