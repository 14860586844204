import createApiServices from "./createApiServices"
import { getRefreshToken } from "../AuthServices"
import { extendUrlFulfillment } from "../../helpers/extenent-url"

const prefixURL = `${extendUrlFulfillment.url}/api/auth` || 'https://fulfillment-staging.merchize.com/api/auth'
const department = process.env.REACT_APP_DEPARTMENT || '657a7c0c0780229a2c9c0cd3'
const api = createApiServices({ baseUrl: prefixURL })

export const refreshToken = (data) => {
    return api.makeAuthRequest({
        url: `/auth/refresh-token?department=${department}`,
        method: 'POST',
        data: {
            refreshToken: getRefreshToken()
        },
    })
}

export const getUserList = (query) => {
    return api.makeAuthRequest({
        url: `users`,
        method: 'GET',
        params: query,
    })
}

export const userSignInAuth = (data) => {
    return api.makeRequest({
        url: `/auth/login?department=${department}`,
        method: 'POST',
        data,
    })
}