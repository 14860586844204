import React, {Component} from 'react'
import 'font-awesome/css/font-awesome.min.css'
import NavbarLeft from "./NavbarLeft"
import HeaderApp from "./HeaderApp"

class DashboardContainer extends Component {
    state = {
        collapsed: false,
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        })
    }

    render() {
        const {path} = this.props
        const {collapsed} = this.state

        return (
            <div className="wrapContainer d-flex">
                <NavbarLeft collapsed={collapsed} path={path}/>
                <div className="site-layout bg-light">
                    <HeaderApp onToggle={this.toggle}/>
                    <div className=" p-3">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardContainer
