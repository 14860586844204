import React, {Component} from 'react'
import PropTypes from 'prop-types'

class FilterInvoices extends Component{
    _onHandleChangeInput = (e) => {
        const {onHandleChangeInput} = this.props
        const {name, value} = e.target

        onHandleChangeInput(name, value)
    }

    render() {
        const {filter, limit, total, onHandleChangePage, onHandleChangeStatus, onHandleResetFilter, onHandleChangeShippingCarrier, onHandleChangeApprovalStatus} = this.props
        const {status, filename, shipping_carrier, approval_status } = filter

        return (
            <div className="FilterInvoices mt-1 mb-3 d-md-flex justify-content-between align-items-baseline">
                <div className="FilterStatus d-sm-flex align-items-center mb-md-0 mb-3">
                    <div className="position-relative InputWrapper mr-3">
                        <i className="iconSearch fa fa-search position-absolute"/>
                        <input onChange={this._onHandleChangeInput} name="filename" value={filename} className="form-control mb-sm-0 mb-3" placeholder="Search file name"/>
                        {filename !== '' && (
                            <i onClick={() => onHandleResetFilter('filename')} className="iconClose fa fa-times-circle position-absolute"/>
                        )}
                    </div>
                    <div className="SelectWrap position-relative d-inline-block mr-3">
                        <select value={status} onChange={onHandleChangeStatus} className="form-control statusFilter">
                            <option value="">All status</option>
                            <option value="created">Created</option>
                            <option value="processing">Processing</option>
                            <option value="done">Done</option>
                            <option value="failed">Failed</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>

                    <div className="SelectWrap position-relative d-inline-block mr-3">
                        <select value={shipping_carrier} onChange={onHandleChangeShippingCarrier} className="form-control shippingCarrierFilter">
                            <option value="">All shipping carrier</option>
                            <option value="multrans_us">Multrans US</option>
                            <option value="multrans_us_fast">Multrans US Fast</option>
                            <option value="multrans_eu">Multrans EU</option>
                            <option value="shippo_us">Shippo US</option>
                            <option value="hw_usps">HW_USPS</option>
                            <option value="hw_yun">HW_YUN</option>
                            <option value="hw_tiktok">HW TIKTOK</option>
                            <option value="zone9_us">Zone9 US</option>
                            <option value="zone9_ca">Zone9 CA</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>

                    <div className="SelectWrap position-relative d-inline-block mr-3">
                        <select value={approval_status} onChange={onHandleChangeApprovalStatus} className="form-control approvalStatusFilter">
                            <option value="">All approval status</option>
                            <option value="rejecting">Rejecting</option>
                            <option value="rejected">Rejected</option>
                            <option value="approving">Approving</option>
                            <option value="approved">Approved</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="SelectWrap position-relative d-inline-block mr-3">
                        <select value={limit} onChange={onHandleChangePage} className="form-control">
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                    <div className="total"><b>{total} {total === 1 ? 'item' : 'items'}</b></div>
                </div>
            </div>
        )
    }
}

FilterInvoices.propTypes = {
    filter: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onHandleChangePage: PropTypes.func.isRequired,
    onHandleChangeStatus: PropTypes.func.isRequired,
    onHandleResetFilter: PropTypes.func.isRequired,
    onHandleChangeShippingCarrier: PropTypes.func.isRequired,
    onHandleChangeApprovalStatus: PropTypes.func.isRequired,
}

export default FilterInvoices