import React, {Component} from 'react'
import 'font-awesome/css/font-awesome.min.css'
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import PropTypes from "prop-types"

class ColInvoiceRecords extends Component {
    state = {
        isOpen: false,
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const {isOpen} = this.state
        const {records} = this.props

        return (
            <div className="RecordsPopup ml-2">
                <span onClick={this._handleToggleModal}><i className="fas fa-external-link text-primary"></i></span>
                <Modal isOpen={isOpen} className="InvoiceRecordsModal" size="lg">
                    <ModalHeader toggle={this._handleToggleModal}>Invoice Records</ModalHeader>
                    <ModalBody>
                        <div className="mt-3">
                            <table className="table">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Created at</th>
                                    <th scope="col">Cost</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    records.length > 0 && (
                                        records.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.Name}</td>
                                                    <td>{item.Address1}</td>
                                                    <td>{item.City}</td>
                                                    <td>{item.Country}</td>
                                                    <td>{item['Created At']}</td>
                                                    <td>${item.Cost}</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="ModalButtons">
                            <button className="btn btn-primary" onClick={this._handleToggleModal}>
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}

ColInvoiceRecords.propTypes = {
    records: PropTypes.array.isRequired
}

export default ColInvoiceRecords