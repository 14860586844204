import React, {Component} from 'react'
import TableLoading from "../../../shared/TableLoading"
import LabelRow from './table/LabelRow'
import PropTypes from "prop-types"

class LabelsTable extends Component {

    render() {
        const {loading, data} = this.props

        return (
            <table className="table">
                <thead className="thead-light">
                <tr>
                    <th className="colOrderNumber">Order number</th>
                    <th className="colStatus">Status</th>
                    <th className="colCreated">Created</th>
                    <th className="colCheckingNumber">Partner tracking number</th>
                    <th className="colCheckingNumber">Tracking number</th>
                    <th className="colShippingCarrier">Shipping carrier</th>
                    <th className="colShippingCountry">Country</th>
                    <th className="colShippingWeight">Weight</th>
                    <th className="colSource">Source</th>
                    <th className="colSource">Detail</th>
                </tr>
                </thead>
                {
                    loading ?
                        <TableLoading columnQuantity={10}/>
                        :
                        <tbody>
                        {data.length > 0 ? (
                            data.map(item => {
                                return (
                                    <LabelRow key={item._id} item={item}/>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={10}>No result</td>
                            </tr>
                        )}
                        </tbody>
                }
            </table>
        );
    }

}

LabelsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
}

export default LabelsTable