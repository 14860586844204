import React, {useState} from 'react'
import PropTypes from 'prop-types'
import PubSub from 'pubsub.js'
import {Form, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import {createSupplierValidation, createSupplierValidationTC} from "../../../../services/api/SupplierValidationServices"
import suppliers from "../../../../shared/suppliers"

CreatedSupplierValidateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired
}

function CreatedSupplierValidateModal(props) {
    const format = /^[a-zA-Z0-9-_. ]*$/

    const [formValidate, _setFile] = useState({
        file: '',
        filename: '',
        supplier: '',
        note: '',
        error: '',
        validate: {
            file: '',
            filename: ''
        },
        loading: false,
    })

    function _validate() {
        const {file, filename, supplier} = formValidate

        _setFile({
            ...formValidate,
            validate: {
                file: file === '' ? 'Vui lòng chọn file CSV.' : '',
                filename: filename.trim() === '' ? 'Vui lòng nhập tên file.' : !format.test(filename) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                supplier: !supplier && 'Vui lòng chọn supplier'
            }
        })
    }

    function _onChangeFile(e) {
        const {files} = e.target
        console.log(files[0].size)

        if (files[0]) {
            const filename = files[0].name.replace(/\.[^.]+$/, '')
            const extension = files[0].name.replace(/.+\.(?=[^.]+$)/, '')

            if (extension !== 'csv' && files[0].name !== 'csv') {
                return _setFile({
                    ...formValidate,
                    validate: {
                        ...formValidate.validate,
                        filename: !format.test(removeAscent(filename)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                        file: 'File upload định dạng CSV. Vui lòng kiểm tra lại.'
                    }
                })
            }
            if (files[0].size > 5000000) {
                return _setFile({
                    ...formValidate,
                    validate: {
                        ...formValidate.validate,
                        file: 'File giới hạn kích thước 5MB. Vui lòng kiểm tra lại.'
                    }
                })
            }

            _setFile({
                ...formValidate,
                file: files[0],
                filename: removeAscent(filename),
                validate: {
                    ...formValidate.validate,
                    filename: !format.test(removeAscent(filename)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : '',
                    file: ''
                }
            }, () => {
                _validate()
            })
        }
    }

    function _onToggle() {
        const {onToggleModal} = props

        if (typeof onToggleModal === 'function') {
            onToggleModal()
        }
    }

    function removeAscent(str) {
        var AccentsMap = [
            "aàảãáạăằẳẵắặâầẩẫấậ",
            "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
            "dđ", "DĐ",
            "eèẻẽéẹêềểễếệ",
            "EÈẺẼÉẸÊỀỂỄẾỆ",
            "iìỉĩíị",
            "IÌỈĨÍỊ",
            "oòỏõóọôồổỗốộơờởỡớợ",
            "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
            "uùủũúụưừửữứự",
            "UÙỦŨÚỤƯỪỬỮỨỰ",
            "yỳỷỹýỵ",
            "YỲỶỸÝỴ",
            " $"
        ]
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g')
            var char = AccentsMap[i][0]
            str = str.replace(re, char)
        }
        return str
    }

    function _handleChangeField(e) {
        const {name, value} = e.target

        if (name === 'filename') {
            _setFile({
                ...formValidate,
                [name]: removeAscent(value),
                validate: {
                    ...formValidate.validate,
                    filename: value.trim() === '' ? 'Vui lòng nhập tên file.' : !format.test(removeAscent(value)) ? 'Tên file không chứa tiếng Việt, không chứa ký tự đặc biệt. Vui lòng kiểm tra lại.' : ''
                }
            })
        } else {
            _setFile({
                ...formValidate,
                [name]: value,
                validate: {
                    ...formValidate.validate,
                    ...(value && {
                        [name]: ''
                    })
                }
            })
        }
    }

    async function _onSubmit() {
        const {partner} = props
        const {file, filename, note, supplier} = formValidate
        const formData = new FormData()

        formData.append('invoice', file)
        formData.append('filename', filename.trim())
        formData.append('type', 'supplier')
        formData.append('supplier', supplier)
        formData.append('note', note)

        if (!file || !format.test(removeAscent(filename))) {
            _validate()
            return
        }

        _setFile({...formValidate, loading: true})

        const {success, message} = partner === "1C" ? await createSupplierValidation(formData) : await createSupplierValidationTC(formData)

        if (!success) {
            return _setFile({...formValidate, loading: false, error: message})
        }

        _onToggle()
        PubSub.publish('LOAD_LIST_SUPPLIER')
        _setFile({...formValidate, loading: false})
    }

    const {isOpen} = props
    const {loading, error, validate, filename, note} = formValidate

    return (
        <Modal isOpen={isOpen} className="CreateInvoiceModal">
            <ModalHeader toggle={_onToggle}>Create supplier validation</ModalHeader>

            <ModalBody>
                <Form>
                    <div className="form-group">
                        <label>Upload file CSV</label>
                        <input
                            type="file"
                            accept=".csv"
                            className="form-control-file"
                            onChange={_onChangeFile}
                        />

                        {
                            validate.file && <div className="text-danger mt-2">{validate.file}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Supplier</label>
                        <select className="form-control" name='supplier' onChange={_handleChangeField}
                                defaultValue={formValidate.supplier}>
                            <option value="">Select supplier</option>
                            {
                                suppliers.map(supplier => {
                                    return <option key={supplier.name} value={supplier.name}
                                    >{supplier.name}</option>
                                })
                            }
                        </select>

                        {
                            validate.supplier && <div className="text-danger mt-2">{validate.supplier}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>File name</label>
                        <input
                            type="text"
                            name='filename'
                            value={filename}
                            className="form-control"
                            onChange={_handleChangeField}
                        />

                        {
                            validate.filename && <div className="text-danger mt-2">{validate.filename}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label>Note</label>
                        <textarea
                            name='note'
                            className="form-control"
                            defaultValue={note}
                            onChange={_handleChangeField}
                        />
                    </div>
                </Form>

                {
                    error && <div className="text-danger mt-3">{error}</div>
                }
            </ModalBody>
            <ModalFooter>
                <div className="ModalButtons">
                    <button className="btn btn-light border mr-2" onClick={_onToggle}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={_onSubmit} disabled={loading}>
                        {loading ? <span>Uploading...</span> : 'Upload'}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CreatedSupplierValidateModal
