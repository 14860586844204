import React, {Component} from 'react'
import CreateRelabelModal from './CreateRelabelModal'
import {csvData} from '../../helpers/template'
import {CSVLink} from "react-csv"

class CreateRelabel extends Component {
    state = {
        isOpen: false,
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const {isOpen} = this.state
        const {partner} = this.props

        return (
            <div className="mb-3">
                <CSVLink
                    data={csvData}
                    filename={"template-relabels.csv"}
                    className="btn btn-link"
                >
                    Download template
                </CSVLink>
                <button type="primary" className="btn btn-primary" onClick={this._handleToggleModal}>Create Relabel</button>
                {isOpen && (
                    <CreateRelabelModal
                        isOpen={isOpen}
                        onToggleModal={this._handleToggleModal}
                        partner={partner}
                    />
                )}
            </div>
        )
    }

}

export default CreateRelabel