export default [
    {
        name: "CW",
        color: '#218cff'
    },
    {
        name: "DBUS",
        color: '#8250ff'
    },
    {
        name: "DPCN",
        color: '#ff7c3d'
    },
    {
        name: "MDUS",
        color: '#545b62'
    },
    {
        name: "PBAU",
        color: '#ff3b15'
    },
    {
        name: "PF",
        color: '#ffc814'
    },
    {
        name: "PL EU",
        color: '#4f8978'
    },
    {
        name: "SPUS",
        color: '#738b12'
    },
    {
        name: "YC",
        color: '#81d4ff'
    },
    {
        name: "PYUS",
        color: '#81d4ff'
    },
    {
        name: "BFUS",
        color: '#81d4ff'
    },
    {
        name: "HF EU",
        color: '#81d4ff'
    },
    {
        name: "PCUK",
        color: '#81d4ff'
    },
    {
        name: "FSUS",
        color: '#81d4ff'
    },
    {
        name: "HW TIKTOK",
        color: '#81d4ff'
    },
].sort((a, b) => a.name.localeCompare(b.name))