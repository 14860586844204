import React, {Component} from 'react'
import PubSub from 'pubsub.js'
import {Link} from 'react-router-dom'
import moment from 'moment'
import humanizeTime from './../../../helpers/time/humanizeTime'
import FilterSupplier from './FilterSupplier'
import PagePagination from "../../../shared/PagePagination"
import TableLoading from './../../../shared/TableLoading'
import ActionReject from './action/ActionReject'
import ActionApprove from './action/ActionApprove'
import changeQuerySearch from '../../../helpers/routing/changeQuerySearch'
import {getSupplierValidation, getSupplierValidationTC} from "../../../services/api/SupplierValidationServices"
import suppliersConfig from "../../../shared/suppliers"

class SupplierPage extends Component {
    state = {
        data: [],
        error: '',
        loading: false,
        page: this.props.currentPage,
        pages: 1,
        limit: this.props.currentLimit,
        total: 0,
        filter: {
            filename: '',
            status: '',
            supplier: ''
        }
    }

    _token = null

    componentDidMount() {
        this.setState({
            filter: {
                ...this.state.filter,
                ...this.props.filters
            }
        }, () => {
            this._handleGetListInvoice()
        })

        this._token = PubSub.subscribe('LOAD_LIST_SUPPLIER', () => {
            this._handleGetListInvoice()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({
                page: this.props.currentPage,
            }, this._handleGetListInvoice)
        }

        if (prevProps.partner !== this.props.partner) {
            this.setState({
                page: 1,
            }, this._handleGetListInvoice)
        }
    }

    componentWillUnmount() {
        this._token && PubSub.unsubscribe(this._token)
    }

    getDefaultParams = () => {
        return this.state.filter
    }

    _handleGetListInvoice = async () => {
        try {
            const {page, limit, filter} = this.state
            const {partner} = this.props
            const {status, filename, supplier} = filter
            const query = {page, limit, status, filename, supplier: supplier || ''}

            this.setState({
                loading: true,
                error: '',
                data : []
            })

            const {data, message, success} = partner === "1C" ? await getSupplierValidation(query) : await getSupplierValidationTC(query)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {requests, pages, total} = data

            this.setState({
                data: requests,
                pages: pages,
                total: total,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }

    }

    _onHandleChangePage = (e) => {
        this.setState({
            limit: e.target.value,
            page: 1
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({...this.getDefaultParams(), limit: e.target.value, page: 1}, true)
    }

    _onHandleChangeStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                status: e.target.value
            },
            page: 1
        }, () => {
            changeQuerySearch({...this.getDefaultParams(), page: 1}, true)
            this._handleGetListInvoice()
        })

    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="badge badge-success">Done</span>
            case 'created':
                return <span className="badge badge-primary">Created</span>
            case 'processing':
                return <span className="badge badge-warning">Processing</span>
            default:
                return <span className="badge badge-danger">Failed</span>
        }
    }

    _switchPage = (page) => {
        this.setState({
            page: page
        }, () => {
            changeQuerySearch({...this.getDefaultParams(), page}, true)
            this._handleGetListInvoice()
        })
    }

    _onHandleChangeInput = (name, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            },
            page: 1
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this._handleGetListInvoice()
            }, 350)
        })

        changeQuerySearch({...this.getDefaultParams(), page: 1}, true)
    }

    _onHandleResetFilter = name => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: ''
            }
        }, () => {
            this._handleGetListInvoice()
        })

        changeQuerySearch({...this.getDefaultParams(), page: 1}, true)
    }

    _getApprovedStatus = (status) => {
        switch (status) {
            case 'rejecting':
                return <span className="badge badge-secondary">Rejecting</span>
            case 'rejected':
                return <span className="badge badge-danger">Rejected</span>
            case 'approving':
                return <span className="badge badge-secondary">Approving</span>
            case 'approved':
                return <span className="badge badge-success">Approved</span>
            default:
                return
        }
    }

    onHandleChangeFilter = (prop) => (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [prop]: e.target ? e.target.value : e
            },
            page: 1
        }, () => {

            changeQuerySearch({...this.getDefaultParams()}, true)

            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this._handleGetListInvoice()
            }, 350)
        })
    }

    onClickSupplier = (item) => (e) => {
        this.onHandleChangeFilter('supplier')(item.partner)
    }

    onHandleClearFilters = () => {
        this.setState({
            filter: {
                status: '',
                supplier: '',
                filename: ''
            }
        }, () => {
            changeQuerySearch({...this.getDefaultParams(), page: 1}, true)
            this._handleGetListInvoice()
        })
    }

    render() {
        const {error, data, filter, limit, page, pages, total, loading} = this.state
        const {currentPage, partner} = this.props

        return (
            <div className="InvoicePage InvoiceSupplierPage">
                <FilterSupplier
                    limit={limit}
                    filter={filter}
                    total={total}
                    onHandleChangePage={this._onHandleChangePage}
                    onHandleChangeStatus={this._onHandleChangeStatus}
                    onHandleChangeInput={this._onHandleChangeInput}
                    onHandleResetFilter={this._onHandleResetFilter}
                    onHandleChangeFilter={this.onHandleChangeFilter}
                    onHandleClearFilters={this.onHandleClearFilters}
                />
                {error && (
                    <div className="text-danger my-3">{error}</div>
                )}
                <table className="table table-striped">
                    <thead className="thead-light">
                    <tr>
                        <th className="colTitle" colSpan={2}>File name</th>
                        <th className="colStatus">Status</th>
                        <th className="colSupplier">Supplier</th>
                        <th className="colCreated">Created</th>
                        <th className="colFinishAt">Finish at</th>
                        <th className="colNote">Note</th>
                        <th className="colActions">Approved status</th>
                    </tr>
                    </thead>
                    {
                        loading ?
                            <TableLoading columnQuantity={8}/>
                            :
                            <tbody>
                            {data.length > 0 ? (
                                data.map(item => {
                                    const {results_statuses_count, approval_status} = item
                                    const approvedStatus = approval_status !== undefined ? approval_status : ''
                                    const {
                                        done, failed
                                    } = {...results_statuses_count}
                                    const supplierConfig = suppliersConfig.find(it => it.name === item.partner)

                                    return (
                                        <tr key={item._id}
                                            title={[`Done ${done || 0}`, `Failed ${failed || 0}`].join(' / ')}>
                                            <td className="colFileNumber">
                                                <Link to={{
                                                    pathname: '/a/supplier-validation/' + item._id,
                                                    state: {currentPage: currentPage, partner : partner},
                                                }}>
                                                    {item.request_number}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/a/supplier-validation/' + item._id,
                                                    state: {currentPage: currentPage},
                                                }}>
                                                    {item.filename}
                                                </Link>
                                            </td>
                                            <td>
                                                {this._getStatus(item.status)}
                                            </td>
                                            <td className="colSupplier">
                                                <span className="badge badge-primary"
                                                      style={supplierConfig ? {backgroundColor: supplierConfig.color} : {}}
                                                      onClick={this.onClickSupplier(item)}>{item.partner}</span>
                                            </td>
                                            <td>{this._validateDate(item.created)}</td>
                                            <td>{this._validateDate(item.finished_at)}</td>
                                            <td className="colNote">{item.note}</td>
                                            <td className="text-center colActions">
                                                {
                                                    ['done', 'failed'].includes(item.status) && (
                                                        approvedStatus !== '' ? (
                                                            <div>
                                                                {this._getApprovedStatus(approvedStatus)}
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex justify-content-center">
                                                                <ActionApprove
                                                                    requestID={item._id}
                                                                    isDetail={false}
                                                                    requestNumber={item.request_number}
                                                                    partner={partner}
                                                                />
                                                                <ActionReject
                                                                    requestID={item._id}
                                                                    isDetail={false}
                                                                    requestNumber={item.request_number}
                                                                    partner={partner}
                                                                />
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={8}>No result</td>
                                </tr>
                            )}
                            </tbody>
                    }
                </table>
                {
                    pages > 1 &&
                    <div className="TableNav mt-5 d-flex justify-content-end">
                        <PagePagination page={page} pages={pages} onChangePage={this._switchPage}/>
                    </div>
                }
            </div>
        )
    }
}

export default SupplierPage