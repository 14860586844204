import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import './index.css'
import { Router, Switch, Route } from 'react-router-dom'
import getHistory from './helpers/routing/getHistory'

const history = getHistory()

// Sentry.setTag('label-hub-webapp', 'LabelHub Webapp')
// Sentry.init({
//     dsn: "https://e32bc8855de84d5985d888461f36d847@o255478.ingest.sentry.io/5456458",
//     integrations: [new Integrations.BrowserTracing(),],
//     tracesSampleRate: 1.0,
//     environment: "production"
// })

ReactDOM.render(
    <Router history={history}>
        <Switch>
            <Route path="/" component={App} />
        </Switch>
    </Router>
    , document.getElementById('root')
)