import api from "../api"
import apiTC from "../apiTC"

export const createInvoiceValidation = (data) => {
    return api.makeAuthRequest({
        url: `requests`,
        method: 'POST',
        data: data
    })
}

export const createInvoiceValidationTC = (data) => {
    return apiTC.makeAuthRequest({
        url: `requests`,
        method: 'POST',
        data: data
    })
}