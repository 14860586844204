import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TableHead from "./data/TableHead"
import TableRow from "./data/TableRow"

class TableData extends Component {
    render() {
        const {loading, listFiles, error, page, limit, partner} = this.props

        return (
            <div className='TableData ShippingErrorList'>
                <table className='table table-hover'>
                    <TableHead/>
                    <tbody>
                    {
                        loading && <tr>
                            <td colSpan={6} className="text-center">Getting data...</td>
                        </tr>
                    }
                    {
                        error
                            ? <tr>
                                <td colSpan={6} className='text-danger text-center'>{error}</td>
                            </tr>
                            :
                            !loading && listFiles.length > 0 && listFiles.map((item, index) =>
                                <TableRow key={item._id} index={index} item={item} page={page} limit={limit} partner={partner}/>
                            )
                    }

                    {
                        !loading && !listFiles.length && <tr>
                            <td colSpan={6} className="text-center">No data</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

TableData.propTypes = {
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    listFiles: PropTypes.array.isRequired
}

export default TableData