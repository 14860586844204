import React, { useState } from 'react'
import * as AuthServices from '../../../services/AuthServices'
import { userSignInAuth } from '../../../services/api/UserService'
import getHistory from '../../../helpers/routing/getHistory'
import { parseJwt } from '../../../shared/parseToken'
import redirect from '../../../shared/redirect'


function LoginPage(props) {
    const history = getHistory()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({})

    const _handleChange = (e) => {
        const { name, value } = e.target
        setError('')

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const _signInValidator = () => {
        const { username, password } = formData

        if (!username || !password) {
            setError('The username or password is incorrect!')

            return false
        }

        return true
    }

    const _handleSubmit = (e) => {
        e.preventDefault()

        const isValid = _signInValidator()

        if (!isValid) return

        _reqSignIn()
    }

    const _reqSignIn = async () => {
        setLoading(true)
        setError('')

        try {
            const { data, success, message } = await userSignInAuth({ ...formData })

            if (!success) {
                setLoading(false)

                return setError(message)
            }

            setLoading(false)

            const { accessToken, permissions, refreshToken, roles, facPermissions, department, ...rest } = data

            if (!Object.keys(permissions).length) {
                return setError(`Your account doesn't have permissions!!!`)
            }

            const { onChangeToken } = props

            if (typeof onChangeToken === 'function') onChangeToken(accessToken)

            const timeExp = parseJwt(accessToken).exp - parseJwt(accessToken).iat

            AuthServices.setAccessToken(accessToken)
            AuthServices.setUserData(permissions)
            AuthServices.setRefreshToken(refreshToken)
            AuthServices.setTimeExp((timeExp - 300) * 1000 + new Date().getTime())
            AuthServices.setRoles(roles)
            AuthServices.setDepartment(department)
            redirect('/')
            // history.push('/')
        } catch (e) {
            setLoading(false)

            if (e && e.response && e.response.status !== 200) {
                setError(e.response.data.message)
            } else {
                setError(e.message)
            }
        }
    }

    return (
        <div className="LoginPage pt-5">
            <div className="LoginInner bg-white p-4 shadow-sm rounded">
                <h1 className="PageTitle">Login</h1>

                <form onSubmit={_handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor=''>Username</label>
                        <input type='text' className='form-control' autoFocus required name='username'
                            onChange={_handleChange} />
                    </div>

                    <div className='form-group'>
                        <label htmlFor=''>Password</label>
                        <input type='password' className='form-control' required name='password'
                            onChange={_handleChange} />
                    </div>

                    <button type='submit' className='btn btn-primary px-4' disabled={loading}>Login
                    </button>
                </form>

                {
                    error && <div className='text-danger mt-3'>{error}</div>
                }
            </div>
        </div>
    )
}

export default LoginPage
